import { defineStore } from "pinia";
import axios from "axios";

export const useSettingsStore = defineStore("settingsStore", {
  state: () => ({
    caller_id: 0,
    caller_all: JSON.parse(localStorage.getItem("caller_all") || "false"),
    callee_all: JSON.parse(localStorage.getItem("callee_all") || "false"),
    caller_name: "",
    caller_phone_number: "",
    caller_instruct: "",
    callee_id: 0,
    callee_name: "",
    callee_phone_number: "",
    family_instruct: "",
    family_slug: "",
    family_id: 0,
    callee_instruct: "",
    el_voice_id: "",
    callee_list: [],
    BACK_URL: "https://back.storycall.org",
    FRONT_URL: "https://storycall.org",
    showModal: false,
    timezone: 5,
    questions: [], 
    ui_string: {},
    question_recording: "",
    question_recording_only: false,
    isRecording: false,
    isCreatingAudio: false,
    audioUrl: "", 
    audioUrls: JSON.parse(localStorage.getItem("audioUrls") || "{}"),
    question_audio_id: "",
  }),
  actions: {
    async fetchSettings() {
      try {
        this.showModal = true;
        const cookies = Object.fromEntries(document.cookie.split("; ").map((cookie) => cookie.split("=")));

        // Restore caller_all and callee_all from localStorage
        this.caller_all = JSON.parse(localStorage.getItem("caller_all") || "false");
        this.callee_all = JSON.parse(localStorage.getItem("callee_all") || "false");
        this.audioUrls = JSON.parse(localStorage.getItem("audioUrls") || "{}");
        const family_id = cookies.family_id;
        console.debug("Logged In Status family_id:", family_id);

        if (family_id) {
          this.family_slug = cookies.family_slug || "";
          if (!this.family_id) {
            this.family_id = parseInt(family_id, 10) || 0;
            console.debug("Updated Family ID from Cookies:", this.family_id);
          }
        }

        // Get caller_id, caller_name, and caller_phone_number from cookies
        this.caller_id = parseInt(cookies.caller_id, 10) || 0;
        this.caller_name = cookies.caller_name || "";
        this.caller_phone_number = cookies.caller_phone_number || "";

        // Get callee_id, callee_name, and callee_phone_number from cookies
        this.callee_id = parseInt(cookies.callee_id, 10) || 0;
        this.callee_name = cookies.callee_name || "";
        this.callee_phone_number = cookies.callee_phone_number || "";

        // Fetch caller_instruct if caller_id is not 0 and caller_instruct is empty
        if (this.caller_id !== 0 && this.caller_instruct === "") {
          const callerResponse = await axios.get(`${this.BACK_URL}/caller/caller_get_byid/${this.caller_id}`, {
            withCredentials: true,
          });
          this.caller_instruct = callerResponse.data.caller_instruct;
        }
        const response = await axios.get(`${this.BACK_URL}/settings/settings_get_all`, {
          withCredentials: true,
        });
        this.family_instruct = response.data.family_instruct;
        this.timezone = response.data.timezone;

        await this.fetchCalleeInstruct();

        if (!this.callee_list.length) {
          this.callee_list = await this.fetchCallee();
        }
        await this.fetchUIStrings();
        // Fetch questions after fetching settings
        await this.question_get_all();
      } catch (error) {
        if (error.response && error.response.status === 404 && error.response.data.detail === "Settings not found for the provided family ID") {
          console.error("Settings not found for the provided family ID, logging out.");
          this.logout();
        } else {
        console.error("Error fetching settings:", error);
        }
        await this.fetchUIStrings();
      } finally {
        this.showModal = false;
      }
    },
    async fetchCallee() {
      console.log("fetchCallee");
      try {
        const response = await axios.get(`${this.BACK_URL}/callee/callee_get_all`, {
          withCredentials: true,
        });
        this.callee_list = response.data;
        return response.data;
      } catch (error) {
        console.error(error.response.data.detail[0].msg);
        alert(`Failed to fetch phone numbers: ${error.response.data.detail[0].msg}`);
      }
    },
    async fetchCalleeInstruct() {
      try {
        const payload = {
          callee_id: this.callee_id,
          caller_id: this.caller_id,
        };
        const response = await axios.post(`${this.BACK_URL}/callee/callee_instruct`, payload, {
          withCredentials: true,
        });
        this.callee_instruct = response.data.callee_instruct;
      } catch (error) {
        console.error(error.response.data.detail);
        alert(`Failed to fetch callee instructions: ${error.response.data.detail}`);
      }
    },
    async saveSettings() {
      try {
        const expires = "expires=Fri, 31 Dec 9999 23:59:59 GMT";
        // Set caller_id, caller_name, and caller_phone_number in cookies
        document.cookie = `caller_id=${this.caller_id};path=/;${expires}`;
        document.cookie = `caller_name=${this.caller_name};path=/;${expires}`;
        document.cookie = `caller_phone_number=${this.caller_phone_number};path=/;${expires}`;

        // Set callee_id, callee_name, and callee_phone_number in cookies
        document.cookie = `callee_id=${this.callee_id};path=/;${expires}`;
        document.cookie = `callee_name=${this.callee_name};path=/;${expires}`;
        document.cookie = `callee_phone_number=${this.callee_phone_number};path=/;${expires}`;

        localStorage.setItem("caller_all", JSON.stringify(this.caller_all));
        localStorage.setItem("callee_all", JSON.stringify(this.callee_all));
        localStorage.setItem("audioUrls", JSON.stringify(this.audioUrls));
        await axios.post(
          `${this.BACK_URL}/settings/settings_create`,
          {
            family_instruct: this.family_instruct,
            callee_instruct: this.callee_instruct,
            timezone: this.timezone,
          },
          {
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    },
    logout() {
      const familyIdCookie = document.cookie.split("; ").find((row) => row.startsWith("family_id="));
      if (familyIdCookie) {
        const lastFamilyId = familyIdCookie.split("=")[1];
        document.cookie = `family_id_last=${lastFamilyId};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
      document.cookie = "family_id=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      document.cookie = "family_id=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.storycall.org";
    },
    logout_all() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.storycall.org";
      }

      localStorage.removeItem("caller_all");
      localStorage.removeItem("callee_all");
      localStorage.clear();
      this.$reset();
    },
    async question_get_all() {
      this.showModal = true;
      try {
        const payload = {
          callee_id: this.callee_id,
          caller_id: this.caller_id,
          caller_all: this.caller_all,
          callee_all: this.callee_all,
          family_id: this.family_id,
        };
        const response = await axios.post(`${this.BACK_URL}/question/question_get_all`, payload, {
          withCredentials: true,
        });
        this.questions = response.data.questions;
        localStorage.setItem("caller_all", JSON.stringify(this.caller_all));
        localStorage.setItem("callee_all", JSON.stringify(this.callee_all));
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.questions = [];
            console.error("Questions not found, setting questions to empty array.");
          } else {
          console.error(`HTTP error! status: ${error.response.status}`);
          }
        } else {
          console.error(`An error occurred: ${error}`);
        }
      }
      this.showModal = false;
    },

    async fetchUIStrings() {
      try {
        const uiStringResponse = await axios.get(`${this.BACK_URL}/sys/ui_string_get`);
        this.ui_string = uiStringResponse.data;
      } catch (error) {
        console.error("Error fetching UI strings:", error);
        this.ui_string = {};
      }
    },
    formatUIString(template, variables) {
      return template.replace(/\[\[(\w+)\]\]/g, (_, key) => variables[key] || "");
    },

    setAudioUrl(questionId, type, url) {
      if (!this.audioUrls[questionId]) {
        this.audioUrls[questionId] = { default: "", generated: "", recorded: "", merged: "" };
      }
      this.audioUrls[questionId][type] = url;
      localStorage.setItem('audioUrls', JSON.stringify(this.audioUrls));
    },

    parseAudioIdFromUrl(url) {
      const match = url.match(/\/question_audio\/([a-zA-Z0-9]+)\.mp3$/);
      return match ? match[1] : null;
    },
  },
});
