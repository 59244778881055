<template>
  <!-- <div class="grid-table-cell-narrow-button column">
    <button @click="handleButtonClick" class="recording-button" :disabled="isCreatingAudio">
      <span v-if="isRecording" class="recording-indicator">●</span>
      {{ buttonText }}
    </button>
  </div> -->

  <!-- Audio Player -->
  <div class="grid-table-cell-wide column">
    <audio ref="audioPlayerRef" :src="audioUrl" controls class="hidden-audio"> </audio>
  </div>


  <div class="grid-table-cell-wide column">
    <div class="button-container">

      <button @click="questionAudioCreate" class="action-button speak-button" :disabled="isCreatingAudio">
        <i class="fas fa-spinner fa-spin" v-if="showModalGenerateAudioId"></i>
        {{ ui_string.read_aloud_button }}
      </button>

      <button @click="handleButtonClick" class="action-button record-button" :disabled="isCreatingAudio">
        <!-- <span v-if="!question_recording" class="recording-indicator" :class="{ 'recording': isRecording }">●</span> -->
        {{ buttonText }}
      </button>

      <button @click="handlePlayPauseClick" class="action-button play-pause-button">
        <i :class="isPlaying ? 'fas fa-pause' : 'fas fa-play'" style="color: black;"></i>
      </button>
    </div>

  </div>


  <div v-if="!question_recording">
    {{ ui_string.recording_instruct_title }}
  </div>
  <div v-else>
    <label class="use-recording-checkbox-container">
      <input type="checkbox" v-model="question_recording_only" class="use-recording-checkbox"
        :disabled="isCreatingAudio">
      <span>{{ ui_string.recording_only_toggle }}</span>
    </label>
  </div>


</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const props = defineProps({
  questionAudioCreate: {
    type: Function,
    required: true,
  },
  updateQuestion: {
    type: Function,
    required: true,
  },
  questionId: {
    type: String,
    required: true,
  },
});

const settings = useSettingsStore();
const { BACK_URL, FRONT_URL, question_recording, question_recording_only, ui_string, isRecording, audioUrls, question_audio_id, audioUrl, isCreatingAudio } = storeToRefs(settings);

const route = useRoute();
const questionId = ref(null);

const mediaRecorder = ref(null);
const questionRecordingChunks = ref([]);
const questionRecordingUrl = ref(null);
const showModalGenerateAudioId = ref(false);
const audioPlayerRef = ref(null);

const buttonText = computed(() => {
  if (isRecording.value) {
    return ui_string.value.recording_stop_button;
  } else if (question_recording.value) {
    return ui_string.value.recording_delete_button;
  } else {
    return ui_string.value.recording_add_button;
  }
});

const handleButtonClick = async () => {
  if (isRecording.value) {
    stopRecording();
  } else if (question_recording.value) {
    deleteRecording();
  } else {
    await startRecording();
  }
};

const startRecording = async () => {
  isRecording.value = true;
  questionRecordingChunks.value = [];

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.value = new MediaRecorder(stream);

    mediaRecorder.value.ondataavailable = (event) => {
      if (event.data.size > 0) {
        questionRecordingChunks.value.push(event.data);
      }
    };

    mediaRecorder.value.onstop = async () => {
      const audioBlob = new Blob(questionRecordingChunks.value, { type: 'audio/webm' });
      await handleAudioFile(audioBlob);
    };

    mediaRecorder.value.start();
  } catch (error) {
    console.error('Error accessing microphone:', error);
    isRecording.value = false;
  }
};

const stopRecording = () => {
  isRecording.value = false;
  mediaRecorder.value.stop();
  // Add this block to stop all tracks in the stream
  if (mediaRecorder.value && mediaRecorder.value.stream) {
    mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
  }
};

const deleteRecording = async () => {
  question_recording.value = null;
  questionRecordingUrl.value = null;
  question_recording_only.value = false;

  if (props.questionId) {
    if (!question_recording_only.value) {
      const defaultAudioUrl = audioUrls.value[props.questionId]?.default;
      const generatedAudioUrl = audioUrls.value[props.questionId]?.generated;
      if (generatedAudioUrl) {
        audioUrl.value = generatedAudioUrl;
        question_audio_id.value = settings.parseAudioIdFromUrl(generatedAudioUrl);
      } else {
        audioUrl.value = defaultAudioUrl;
        question_audio_id.value = settings.parseAudioIdFromUrl(defaultAudioUrl);
      }
    }

    settings.setAudioUrl(props.questionId, 'recorded', '');
    settings.setAudioUrl(props.questionId, 'merged', '');
  }

  try {
    await props.updateQuestion();
  } catch (error) {
    console.error('Error updating question after deleting recording:', error);
  }
};

const handleAudioFile = async (audioBlob) => {
  questionRecordingUrl.value = URL.createObjectURL(audioBlob);
  console.log('Audio file:', audioBlob);

  const formData = new FormData();
  formData.append('file', audioBlob, 'question_recording.webm');

  try {
    const response = await axios.post(`${BACK_URL.value}/ai/question_recording_create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    question_recording.value = response.data.question_audio_id;
    question_audio_id.value = response.data.question_audio_id;
    console.log('File uploaded successfully:', response.data);
    settings.setAudioUrl(
      props.questionId,
      'recorded',
      `${FRONT_URL.value}/question_audio/${question_recording.value}.mp3`
    );

    await props.questionAudioCreate();
    await props.updateQuestion();
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    isRecording.value = false;
  }
};

const playAudio = async () => {
  console.log('about to play audio');
  console.log('audioUrl:', audioUrl.value);
  console.log('isRecording:', isRecording.value);
  await nextTick(); // Ensure the audio element is rendered

  if (audioPlayerRef.value) {
    audioPlayerRef.value.currentTime = 0;
    console.log('audio player ref', audioPlayerRef.value);
    audioPlayerRef.value.play().catch(error => {
      console.error('Error playing audio:', error);
    });
  } else {
    console.log('audioPlayerRef.value is null');
  }
};

const isPlaying = ref(false);

const handlePlayPauseClick = () => {
  if (audioPlayerRef.value) {
    if (isPlaying.value) {
      audioPlayerRef.value.pause();
    } else {
      audioPlayerRef.value.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    }
    isPlaying.value = !isPlaying.value;
  }
};

onMounted(() => {
  questionId.value = route.params.id;

  if (questionId.value && audioUrls.value[questionId.value]?.recorded) {
    const recordedUrl = audioUrls.value[questionId.value].recorded;
    question_recording.value = settings.parseAudioIdFromUrl(recordedUrl);
  }

  if (audioPlayerRef.value) {
    audioPlayerRef.value.addEventListener('play', () => {
      isPlaying.value = true;
    });
    audioPlayerRef.value.addEventListener('pause', () => {
      isPlaying.value = false;
    });
    audioPlayerRef.value.addEventListener('ended', () => {
      isPlaying.value = false;
    });
  }
  // Play the audio when the component is mounted
  if (audioPlayerRef.value && audioUrl.value) {
    audioPlayerRef.value.src = audioUrl.value;
    audioPlayerRef.value.load();
    audioPlayerRef.value.play().catch(error => {
      console.error('Error playing audio on mount:', error);
    });
  }
});

onUnmounted(() => {
  question_recording.value = null;
  question_recording_only.value = false;
  if (questionRecordingUrl.value) {
    URL.revokeObjectURL(questionRecordingUrl.value);
  }

  // Add this block to ensure the stream is stopped when the component is unmounted
  if (mediaRecorder.value && mediaRecorder.value.stream) {
    mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
  }

  if (audioPlayerRef.value) {
    audioPlayerRef.value.removeEventListener('play', () => {
      isPlaying.value = true;
    });
    audioPlayerRef.value.removeEventListener('pause', () => {
      isPlaying.value = false;
    });
    audioPlayerRef.value.removeEventListener('ended', () => {
      isPlaying.value = false;
    });
  }
});

watch(question_recording_only, async (newValue) => {
  if (newValue) {
    const recordedUrl = audioUrls.value[props.questionId]?.recorded;
    if (recordedUrl) {
      audioUrl.value = recordedUrl;
      question_audio_id.value = settings.parseAudioIdFromUrl(recordedUrl);
      try {
        await props.updateQuestion();
      } catch (error) {
        console.error("Failed to update question with recorded audio ID:", error);
      }
    }
  } else {
    const mergedAudioUrl = audioUrls.value[props.questionId]?.merged;
    if (mergedAudioUrl) {
      audioUrl.value = mergedAudioUrl;
      question_audio_id.value = settings.parseAudioIdFromUrl(mergedAudioUrl);
      try {
        await props.updateQuestion();
      } catch (error) {
        console.error("Failed to update question with merged audio ID:", error);
      }
    }
  }
});

watch(() => audioUrl.value, async (newUrl, oldUrl) => {
  console.log('audioUrl changed from', oldUrl, 'to', newUrl);
  if (newUrl) {
    await nextTick();
    await playAudio();
  }
});
</script>

<style scoped>
.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recording-indicator {
  color: black;
  margin-right: 5px;
}

.recording-indicator.recording {
  color: red;
}

.use-recording-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.use-recording-checkbox {
  margin-right: 12px;
  appearance: none;
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.use-recording-checkbox:checked::after {
  content: '';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.use-recording-checkbox::after {
  content: '\2714';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.use-recording-checkbox:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.use-recording-checkbox-container span {
  color: #ffffff;
}

.use-recording-checkbox:disabled+span {
  color: #a0a0a0;
}

.recording-button {
  height: 55px;
}

.recording-button:disabled,
.use-recording-checkbox:disabled+span {
  opacity: 0.5;
  cursor: not-allowed;
}




/* record buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* max-width: 300px; */
  margin: 10px 0;
}

.action-button {
  flex: 1;
  margin-right: 5px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-button:last-child {
  margin-right: 0;
}


.recording-indicator {
  color: black;
  margin-right: 5px;
}

.recording-indicator.recording {
  color: red;
}

.hidden-audio {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}
</style>
