<template>
    <div class="grid-table-container">
        <div class="grid-table">
            <TopBar />
            <!-- <FamilyNavBar /> -->
            <div style="margin-bottom: 15px"></div>
            <div class="grid-table-cell-wide column" v-if="families.length">
                <h3>All Families</h3>
                <table class="label-text">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Slug</th>
                            <th>Password</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="family in families" :key="family.id">
                            <td>{{ family.id }}</td>
                            <td>{{ family.name }}</td>
                            <td>{{ family.family_slug }}</td>
                            <td>{{ family.password }}</td>
                            <td><button @click="deleteFamily(family.id)">X</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import TopBar from "./TopBar.vue";
import FamilyNavBar from "./FamilyNavBar.vue";
import axios from "axios";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
const settings = useSettingsStore();
const families = ref([]);
const { showModal, BACK_URL } = storeToRefs(settings);


const fetchFamilies = async () => {
    try {
        const response = await axios.get(`${BACK_URL.value}/family/family_get_all`);
        console.log("Families fetched successfully:", response.data);
        if (response.status === 200) {
            families.value = response.data;
        } else {
            throw new Error("Failed to fetch families.");
        }
    } catch (error) {
        console.error("Failed to fetch families:", error);
        alert("Failed to fetch families.");
    }
};
const deleteFamily = async (familyId) => {
    try {
        const response = await axios.delete(`${BACK_URL.value}/family/family_delete/${familyId}`);
        if (response.status === 200) {
            families.value = families.value.filter(family => family.id !== familyId);
            alert("Family deleted successfully.");
        } else {
            throw new Error("Failed to delete family.");
        }
    } catch (error) {
        console.error("Failed to delete family:", error);
        alert("Failed to delete family.");
    }
};
onMounted(async () => {
    await fetchFamilies();
});
</script>
