<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <NavBar />
      <div class="grid-table-cell-wide column">
        <div class="bottom-margin"></div>
        <input type="text" v-model="callStatusWithEmoji" placeholder="Call Status" disabled class="call-status" />

      </div>
      <div class="grid-table-cell-wide column">
        <input type="text" v-model="questionSubject" placeholder="Title" />
        <div class="bottom-margin"></div>

        <textarea v-model="questionText" placeholder="Question" rows="10"></textarea>
        <div v-if="audioUrl">
          <audio :src="audioUrl" controls></audio>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, caller_phone_number, BACK_URL, FRONT_URL, ui_string } = storeToRefs(settings);

const callSid = ref("");
const questionMappings = ref([]);
const questionId = ref("");
const questionSubject = ref("");
const questionText = ref("");
const audioUrl = ref("");
const questionAudioId = ref("");
const callStatus = ref("");
const intervalId = ref(null); 
const answeredBy = ref("");

const callStatusWithEmoji = computed(() => {
  switch (callStatus.value) {
    case "queued":
      return "Queued... 🕒";
    case "ringing":
      return "Ringing... 📶";
    case "in-progress":
      return "In Progress... ⏳";
    case "completed":
      return "Completed... ✅";
    case "busy":
      return "Busy... 🚫";
    case "failed":
      return "Failed... ❌";
    case "no-answer":
      return "No Answer... 📵";
    default:
      return callStatus.value;
  }
});

const dial = async (mapping) => {
  let missingField = "";
  if (!mapping.callee_phone_number) missingField = "call to phone number";
  else if (!caller_phone_number.value) missingField = "call from number";
  else if (!audioUrl.value) missingField = "audio URL";
  else if (!questionId.value.toString()) missingField = "question ID";

  if (missingField) {
    alert(`The "${missingField}" field is blank. Dialing cancelled.`);
    return; 
  }

  const userConfirmed = window.confirm(settings.formatUIString(ui_string.value.call_now_dialog, {
    callee_name: mapping.callee_name,
    callee_phone: mapping.callee_phone_number
  }));

  if (userConfirmed) {
    try {
      const response = await fetch(`${BACK_URL.value}/twilio/call_initiate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          callee_phone_number: mapping.callee_phone_number,
          caller_phone_number: caller_phone_number.value,
          question_audio_url: audioUrl.value,
          question_id: questionId.value.toString(),
        }),
      });
      if (response.ok) {
        const data = await response.json();
        mapping.callSid = data.sid;
        callSid.value = data.sid;
        startCallStatusInterval();
      } else {
        const errorData = await response.json();
        alert(`Failed to initiate call: ${errorData.detail}`);
        console.error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(`HTTP error! status: ${error.response?.status || error}`);
    }
  } else {
    alert("Incorrect input. Dialing cancelled.");
    router.push("/question_list");
  }
};

const loadQuestionAndDial = async () => {
  showModal.value = true;
  const id = router.currentRoute.value.params.id;
  try {
    const response = await axios.get(`${BACK_URL.value}/question/question_get_byid/${id}`);
    if (response.data) {
      questionId.value = response.data.id;
      questionSubject.value = response.data.question_subject;
      questionText.value = response.data.question_text;
      questionAudioId.value = response.data.question_audio_id;
      questionMappings.value = response.data.question_phone_mappings;
      audioUrl.value = `${FRONT_URL.value}/question_audio/${response.data.question_audio_id}.mp3`;
    }
    for (const mapping of questionMappings.value) {
      await dial(mapping);
    }
  } catch (error) {
    console.error(`Failed to fetch question with id ${id}: ${error}`);
    router.push("/question_list");

  }

  showModal.value = false;
};

const startCallStatusInterval = () => {
  intervalId.value = setInterval(async () => { 
    console.log(`Checking call status for SID: ${callSid.value}`);
    if (callSid.value) {
      try {
        const response = await axios.get(`${BACK_URL.value}/twilio/call_status_get_bysid/${callSid.value}`);
        console.log(`Call status response:`, response.data);
        callStatus.value = response.data.status;
        answeredBy.value = response.data.answered_by;
        if (callStatus.value === "completed") {
          clearInterval(intervalId.value);
          console.log("Call completed. Stopping status check interval.");
        }
      } catch (error) {
        console.error(`Failed to fetch call status for SID ${callSid.value}: ${error}`);
      }
    }
  }, 2000);
};

onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value);
});

onMounted(async () => {
  await settings.fetchSettings();
  await loadQuestionAndDial();
  window.scrollTo(0, 0);
});
</script>
<style scoped>
.call-status {
  background-color: white; 
  color: black;
  width: 60%; 
  height: 40px; 
  border: none;
  border-radius: 20px; 
  padding: 0 15px; 
  font-size: 16px; 
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 
  text-align: center;
}
</style>
