<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div  class="nav-bar bottom-margin"></div>

      <div class="grid-table-cell-wide column top-bar-title">{{ui_string.callee_list_title}}</div>

      <template v-for="(item, index) in phoneNumbers" :key="item.id" class="phone-number-item">
        <div @click="setToAnswerCalls(item)" class="grid-table-cell-wide row inlayed inlayed-top row-no-buttons">
          <div style="display: flex; justify-content: space-between; width: 100%">
            <span> {{ item.callee_name }}</span>
            <span @click.stop="navToCalleeUpdate(item.id)" style="cursor: pointer;" class="three-dots">•••</span> 
          </div>
        </div>
      </template>
      <div style="margin-bottom: 15px"></div>
      <div class="grid-table-cell-narrow column">
        <button @click="calleeCreate">{{ui_string.callee_new_button}}</button>
      </div>
      <div class="bottom-margin"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const { showModal, BACK_URL, family_id, callee_id, callee_name, callee_phone_number, ui_string } = storeToRefs(settings);
const router = useRouter();
let phoneNumbers = ref([]);



const calleeCreate = () => {
  router.push('/callee_create');
};

const calleeGetAll = async () => {

  try {
    showModal.value = true;
    const { data } = await axios.get(`${BACK_URL.value}/callee/callee_get_all`, {
      withCredentials: true,
    });
    phoneNumbers.value = data.sort((a, b) => a.callee_name.localeCompare(b.callee_name));
  } catch ({ response }) {
    const errorMessage = response?.data?.detail[0]?.msg || "An error occurred while fetching phone numbers.";
    console.error("Failed to fetch phone numbers:", errorMessage);
    alert(`Failed to fetch phone numbers: ${errorMessage}`);
  } finally {
    showModal.value = false;
  }
};

const navToCalleeUpdate = (item_id) => {
  router.push(`/callee_update/${item_id}`);
};

const setToAnswerCalls = async (item) => {
  callee_id.value = item.id;
  callee_name.value = item.callee_name;
  callee_phone_number.value = item.phone_number;
  await settings.saveSettings();
  router.push('/question_list');
};

onMounted(async () => {
  await calleeGetAll();
});
</script>

<style scoped></style>
