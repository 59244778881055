<template>
  <div class="top-bar">
    <div class="grid-table-cell-logo setting" @click="navToHome" style="margin-top: 2.5%; cursor: pointer">
      <img class="logo" :src="`${settings.FRONT_URL}/storycall-logo.png`" />
    </div>
    <div class="settings" style="cursor: pointer">
      <font-awesome-icon v-if="isConfigUrlPath" icon="xmark" @click="handleSettingsClick" />
      <font-awesome-icon v-else icon="bars" @click="handleSettingsClick" />
    </div>
  </div>
</template>
<script setup>
import { computed, ref ,onMounted} from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRouter } from "vue-router";
import { useSettingsStore } from "../store";

const router = useRouter();
const settings = useSettingsStore();

const navToHome = () => {
  router.push("/");
};
const navToCallee = () => {
  router.push("/callee");
};
const navToCaller = () => {
  router.push("/caller");
};
const navToFamilyCreate = () => {
  router.push("/family_create");
};

const isConfigUrlPath = computed(() => {
  return router.currentRoute.value.path.includes("/settings") || router.currentRoute.value.path.includes("/callee") || router.currentRoute.value.path.includes("/caller");
});
const isFamilyJoinUrlPath = computed(() => {
  return router.currentRoute.value.path.includes("/family_join");
});

const handleSettingsClick = () => {
  if (isFamilyJoinUrlPath.value) {
    navToFamilyCreate();
  } else if (isConfigUrlPath.value) {
    navToHome();
  } else {
    navToCaller();
  }
};
onMounted(async () => {
  await settings.fetchSettings();
});
</script>

<style>
.grid-table-cell-logo {
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.logo {
  max-height: 55px;
  margin-left: 1.5%;
}

.settings {
  cursor: pointer;
  font-size: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
